/**
 * Url para imagenes estaticas u otros valores almacenados en S3
 */
export const baseImages =
  'https://landing-staging-bucket.s3-us-west-1.amazonaws.com/media/static';

const STATIC_IMAGES = {
  MAIN_BACKGROUND: `${baseImages}/home/background.png`,
  PERFILETTO_LOGO: `${baseImages}/logos/white.svg`,
  LANDING_CATALOG: `${baseImages}/cards/catalog/catalog.jpg`,
  LANDING_APP_PHOTO: `${baseImages}/cards/catalog/app.png`,
  LANDING_ABOUT: `${baseImages}/cards/about_perfiletto.png`,
  LANGUAGE_IMAGE_ES: `${baseImages}/languaje/es.svg`,
  LANGUAGE_IMAGE_EN: `${baseImages}/languaje/en.svg`,
  SIDEBAR_PROFILES: `${baseImages}/menu/profiles.png`,
  SIDEBAR_CHAPES: `${baseImages}/menu/herrajes.png`,
  SIDEBAR_MORE: `${baseImages}/menu/more.png`,
  SIDEBAR_MORE_DOORS: `${baseImages}/menu/more.png`,
  SIDEBAR_MORE_PLASTICS: `${baseImages}/menu/more.png`,
  SIDEBAR_MORE_ACRYLICS: `${baseImages}/menu/more.png`,
  SIDEBAR_MORE_AGGLOMERATES: `${baseImages}/menu/more.png`,
  SIDEBAR_COLORS: `${baseImages}/menu/colors.png`,
  SIDEBAR_CATALOG: `${baseImages}/menu/catalog.png`,
  SIDEBAR_VIDEOS: `${baseImages}/menu/videos.png`,
  SIDEBAR_BLOG: `${baseImages}/menu/blog.png`,
  SIDEBAR_GALLERIES: `${baseImages}/menu/fotogaleria.png`,
  SIDEBAR_CONTACT: `${baseImages}/menu/contact.png`,
  VIEWER_SWITCHER_SELLER: `${baseImages}/profiles/aluminero.png`,
  VIEWER_SWITCHER_CUSTOMER: `${baseImages}/profiles/cliente.png`,
  VIDEO_BACKGROUND_IMAGE: `${baseImages}/video/poster.jpg`,
  CLUSTERER_IMAGES: `${baseImages}/clusterer/m`,
};

// eslint-disable-next-line import/prefer-default-export
export { STATIC_IMAGES };
