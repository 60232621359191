import { configureStore } from "@reduxjs/toolkit";
// import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// export function configureStore(initialState) {
//   const store = createStore(
//     reducers,
//     initialState,
//     compose(applyMiddleware(...middlewares))
//   );

//   sagaMiddleware.run(sagas);

//   if (module.hot) {
//     module.hot.accept('./reducers', () => {
//       const nextRootReducer = require('./reducers');
//       store.replaceReducer(nextRootReducer);
//     });
//   }

//   return store;
// }

// const store = configureStore();

// export default store;


// import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from 'redux-saga';
// import { localStorageUpdate } from "./middleware";
// import rootReducer from './root-reducer';
// import rootSaga from './root-saga';

// const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

sagaMiddleware.run(sagas);

export default store;
