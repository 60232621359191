import { CHANGE_LOCALE, CHANGE_LOCALEIMAGE } from '../actions';
import { setCurrentLanguage, setCurrentLanguageImage } from '../../helpers/Utils';

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const changeLocaleImage = (localeImage) => {
  setCurrentLanguageImage(localeImage);
  return {
    type: CHANGE_LOCALEIMAGE,
    payload: localeImage,
  }
}
