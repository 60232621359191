/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import 'video.js/dist/video-js.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-multi-carousel/lib/styles.css';

import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
  defaultRadius,
} from './constants/defaultValues';
import {
  getCurrentColor,
  setCurrentColor,
  setCurrentRadius,
} from './helpers/Utils';
import 'moment/locale/es';

const color = (isMultiColorActive || isDarkSwitchActive) ? getCurrentColor() : defaultColor;
setCurrentColor(color);

setCurrentRadius(defaultRadius);

// setBackground();

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
