import {
  defaultDirection,
  defaultLocale,
  defaultLocaleImage,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from '../constants/defaultValues';
import { STATIC_IMAGES } from '../constants/staticImages';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor;
};

export const setBackground = () => {
  try {
    var background = document.createElement("DIV");

    background.style.backgroundImage = `url('${STATIC_IMAGES.MAIN_BACKGROUND}')`;
    background.style.backgroundPosition = 'center center';
    background.style.backgroundRepeat = 'repeat';
    background.style.position = 'absolute';
    background.style.zIndex = '-1';
    background.style.opacity = '0.3';
    background.style.width = '100%';
    background.style.top = '0';
    background.style.right = '0';
    background.style.bottom = '0';
    background.style.left = '0';
    document.body.prepend(background);
  } catch (error) {
    console.log(">>>>: setting background -> error", error)
  }
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
};

export const getCurrentLanguageImage = () => {
  let img = defaultLocaleImage;
  try {
    img = localeOptions.filter(
      (x) => x.id === localStorage.getItem('currentLanguage')
    )[0].img;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguageImage -> error", error)
    img = defaultLocaleImage;
  }
  return img;
};
export const setCurrentLanguageImage = (localeImage) => {
  try {
    localStorage.setItem('currentLanguageImage', localeImage);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguageImage -> error", error)
  }
}

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('gogo_current_user') != null ? JSON.parse(localStorage.getItem('gogo_current_user')) : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
    user = null;
  }
  return user;
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('gogo_current_user')
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
  }
};

// Obtiene el locale con el nombre adecuado para usar en los
// endpoints
export const getLocaleEP = (locale) => {
  let ret =
    locale !== null && typeof locale !== 'undefined' && locale !== ''
      ? locale
      : defaultLocale;

  if (ret === 'es') {
    ret = 'esp';
  } else if (ret === 'en') {
    ret = 'eng';
  } else {
    return defaultLocale;
  }

  return ret;
};

/**
 * Setea animacion css y maneja eventos para remover animacion y dejar estado final. 
 * @param {Node} elem Elemento al que se aplica la animacion
 * @param {String} animation Nombre de la animacion css a ser aplicada.
 * @param {Boolean} finishedClass Indica el nombre de la clase que se setea al finalizar la animacion. 
 */
export const animate = (
  elem,
  animation,
  finishedClass = 'shown',
  removeClass = []
) => {
  if (!elem || !animation) return;

  const listenerEnd = (event) => {
    elem.classList.remove(animation);

    if (finishedClass !== '') elem.classList.add(finishedClass);

    if (removeClass.length > 0)
      removeClass.forEach((removeName) => {
        elem.classList.remove(removeName);
      });

    elem.removeEventListener('webkitAnimationEnd', listenerEnd, false);
    elem.removeEventListener('animationend', listenerEnd, false);
  };

  elem.addEventListener('webkitAnimationEnd', listenerEnd, false);
  elem.addEventListener('animationend', listenerEnd, false);

  elem.removeAttribute('hidden');
  elem.classList.add(animation);
};


export const inViewport = (elem, heightPercentage = 1.0) => {
  const position = elem.getBoundingClientRect();

  return (
    Math.floor(position.top) >= 0 && Math.floor(position.left) >= 0 &&
    Math.floor(position.bottom) <= Math.floor(((window.innerHeight || document.documentElement.clientHeight) * heightPercentage)) &&
    Math.floor(position.right) <= Math.floor(((window.innerWidth || document.documentElement.clientWidth)))
  );
};


export const setPerfilettoChat = (container, chat1, chat2) => {
  // Start Chat - All Departments
  const phplive_e_1530995793 = document.createElement('script');
  phplive_e_1530995793.type = 'text/javascript';
  phplive_e_1530995793.async = true;
  phplive_e_1530995793.src = '//chat.perfiletto.com/js/phplive_v2.js.php?v=0|1530995793|0|';
  chat1.appendChild(phplive_e_1530995793);
  // container.getElementById('chat_1').appendChild(chat1);
  // document
  //   .getElementById('phplive_btn_1530995793')
  //   .appendChild(phplive_e_1530995793);
  // End Chat - All Departments

  // Start Chat - Service Quality
  const phplive_e_1530995948 = document.createElement('script');
  phplive_e_1530995948.type = 'text/javascript';
  phplive_e_1530995948.async = true ;
  phplive_e_1530995948.src = '//chat.perfiletto.com/js/phplive_v2.js.php?v=36|1530995948|0|';
  chat2.appendChild(phplive_e_1530995948);
  // container.getElementById('chat_2').appendChild(chat2);
  // document
  //   .getElementById('phplive_btn_1530995948')
  //   .appendChild(phplive_e_1530995948);
  // End Chat - Service Quality
};

export const createMarkup = (htmlData) => {
  return { __html: htmlData };
};

export const scrollTopWindow = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};