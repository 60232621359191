import { CHANGE_LOCALE, CHANGE_LOCALEIMAGE } from '../actions';
import { getCurrentLanguage, getCurrentLanguageImage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  localeImage: getCurrentLanguageImage()
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload};
    case CHANGE_LOCALEIMAGE:
      return { ...state, localeImage: action.payload};
    default:
      return { ...state };
  }
};
