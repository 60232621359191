import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import settings_info from './settings/reducer-toolkit';

const reducers = combineReducers({
  menu,
  settings,
  settings_info
});

export default reducers;
