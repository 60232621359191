import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  landing_info_catalog: "Lo más nuevo en diseño",
  landing_info_app: "App Perfiletto te permite generar tablas de corte para cancelería de aluminio; de una manera simple y precisa.",
  timestamp: 0,
  locale: 'es',
  landing_multimedia: null,
};

export const settingsSlice = createSlice({
  name: 'info',
  initialState: initialData,
  reducers: {
    setLandingMultimedia: (state, action) => {
      return {
        ...state,
        timestamp: Date.now(),
        landing_multimedia: action.payload.data,
        locale: action.payload.locale,
      };
    },
  }
});

export const {
  setLandingMultimedia
} = settingsSlice.actions;

export default settingsSlice.reducer;