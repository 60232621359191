import { UserRole } from '../helpers/authHelper';
import { STATIC_IMAGES } from './staticImages';
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const defaultLocaleImage = STATIC_IMAGES.LANGUAGE_IMAGE_ES;
export const localeOptions = [
  {
    id: 'en',
    name: 'English',
    img: STATIC_IMAGES.LANGUAGE_IMAGE_EN,
    direction: 'ltr',
  },
  {
    id: 'es',
    name: 'Español',
    img: STATIC_IMAGES.LANGUAGE_IMAGE_ES,
    direction: 'ltr',
  },
];

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
}

export const adminRoot = '';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = '/search';
export const servicePath = 'https://api.coloredstrategies.com';
export const avisoPrivacidad =
  'https://perfiletto-landing-page-bucket.s3-us-west-1.amazonaws.com/media/privacy-policy/archivetempaviso_Perfiletto.pdf';
export const techSpecs = "https://landing-staging-bucket.s3.us-west-1.amazonaws.com/media/static/profiles/ficha_tecnica_aluminio.pdf";
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'dark.greysteel';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const defaultRadius = 'rounded';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const yumpuCatalogSrc = "https://www.yumpu.com/es/embed/view/TaGpdzmqbZlKhtM0";
// https://www.yumpu.com/es/document/read/66814344/perfiletto-catalogo
// https://www.yumpu.com/es/document/read/66814344/perfiletto-catalogo
export const yumpuJsScript = "https://players.yumpu.com/modules/embed/yp_r_iframe.js";

/**
 * Constantes de elementos dentro de la app Perfiletto
 */
export const SEARCH_TEXT_MIN_LENGTH = 2;
export const WIDTH_IMAGE = 640;
export const HEIGHT_IMAGE = 360;
export const heightRatio = HEIGHT_IMAGE / WIDTH_IMAGE;

// Tamaño de imagen para detalle de producto
// PíctureSlide, ItemDetailPage
export const ITEM_DETAIL_IMAGE_WIDTH = WIDTH_IMAGE;
export const ITEM_DETAIL_IMAGE_HEIGHT = HEIGHT_IMAGE;
export const ITEM_DETAIL_IMAGE_HEIGHT_RATO = heightRatio;

// Tamaño de imagen para lista de articulos en una subcategoria
// seccion donde se muestra la lista de items de una subcategoria
// ItemsSubcategoryPage
export const ITEM_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const ITEM_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de subcategorias en una categoria
// herrajes y perfiles
// SubcategoryList, ChapesListPage
export const SUBCAT_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const SUBCAT_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de tipos de articulos para
// herrajes
// TypeItemsList
export const ITEMTYPE_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const ITEMTYPE_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de subcategorias en la seccion mas producto
// indice mas productos, subcategorias de mas producto
// SubcategoryList
export const MORE_SUBCAT_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const MORE_SUBCAT_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de tipos de acabados
// indice mas productos, subcategorias de mas producto
// TypesPageList
export const COLORS_TYPES_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const COLORS_TYPES_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de acabados
// TypeColorsList
export const COLORS_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const COLORS_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de distribuidores
// ContactViewer
export const CONTACT_DIST_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const CONTACT_DIST_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para informacion de sucursales
// InfoDistributor
export const CONTACT_BRANCH_INFO_IMAGE_WIDTH = WIDTH_IMAGE;
export const CONTACT_BRANCH_INFO_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para lista de galerias
// GalleriesListPage
export const GALLERY_LIST_IMAGE_WIDTH = WIDTH_IMAGE;
export const GALLERY_LIST_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para detalle de galeria
// GalleriesListPage
export const GALLERY_DETAIL_IMAGE_WIDTH = WIDTH_IMAGE;
export const GALLERY_DETAIL_IMAGE_HEIGHT = HEIGHT_IMAGE;

// Tamaño de imagen para galeria en landing page
// GalleriesListPage
export const GALLERY_LANDING_IMAGE_WIDTH = 444;
export const GALLERY_LANDING_IMAGE_HEIGHT = 250;
export const GALLERY_LANDING_IMAGE_HEIGHT_RATIO = heightRatio;


// Tamaño de imagen para detalle de producto
// BlogListPage
export const BLOG_LIST_ITEMS_IMAGE_WIDTH = WIDTH_IMAGE;
export const BLOG_LIST_ITEMS_IMAGE_HEIGHT = HEIGHT_IMAGE;
export const BLOG_LIST_ITEMS_IMAGE_HEIGHT_RATO = heightRatio;